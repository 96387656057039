<style lang="scss">
// .delete-dialog-card {
//   padding: 20px;
//   .headline {
//     font-size: 14px !important;
//     margin-bottom: 20px;
//   }
//   button {
//     font-size: 14px;
//     &.close-dialog {
//       border: solid 1px #173d8f;
//       color: #173d8f;
//     }
//     &.delete {
//       background-color: #d9390d;
//       background: #d9390d!important;
//       color: #ffffff;
//     }
//   }
// }
</style>

<template>
  <b-modal
      id="modal-danger"
      ok-variant="danger"
      cancel-variant="outline-secondary"
      :cancel-title="$t('golf_common_back')"
      :ok-title="$t('golf_common_confirm')"
      modal-class="modal-danger"
      centered
      size="xs"
      :title="ob.title"
      v-model="deleteDialog"
      @ok="deleteItem"
      @hidden="resetModal"
    >
      <b-card-text>
      <div style="padding: 20px 16px"> {{ob.content}}</div>
      </b-card-text>
    </b-modal>
</template>

<script>
export default {
  props: {
    dataRemove: Object
  },
  data() {
    return {
      deleteDialog: false,
      item: null,
      ob: this.dataRemove
    };
  },
  methods: {
    open(item) {
      this.deleteDialog = true;
      this.item = item;
    },
    close() {
      this.deleteDialog = false;
    },
    deleteItem() {
      this.$emit("deleteItem", this.item);
      this.close();
    },
    resetModal() {
      this.$emit("resetModal", this.item);
      this.close();
    }
  }
};
</script>
