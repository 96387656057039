<template>
</template>

<script>
  import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12} from '@/api/constant.js'
export default {
  name: 'Hotkeys',
  data: () => ({
    supportedShortcuts: [],
    ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12]
  }), // data
  props: {
    shortcuts: {
      type: Array,
      required: true
    },
    allChildComponentClosed: {
      type: Array
    },
    isActive: {
      type: Boolean
    }
  },
  methods: {

    onUseKeyboardShortcuts(evt) {
      var checkallChildComponentClosed = this.allChildComponentClosed.filter(x => x.IsActive == true)
      const key = evt.keyCode || evt.which;
      // console.log(key)
      const isKeySupported = this.shortcuts.some(x => x === key);
      if (this.isActive && isKeySupported && checkallChildComponentClosed.length === 0) {
        this.$emit('triggered', { key,evt});
        if(this.ShortCutKey.includes(key) || evt.ctrlKey) {
          evt.preventDefault();
        }
      }
    }
  }, 
  
 // created
  /**
   * Overwrite the "keydown" event and enable CMD+S for saving.
   */
  mounted() {
    document.addEventListener('keydown', this.onUseKeyboardShortcuts, false);
  }, // mounted
  /**
   * Disable CMD+S for saving and restore the original "keydown" event.
   */
  beforeDestroy() {
    document.removeEventListener('keydown', this.onUseKeyboardShortcuts);
  }
};
</script>