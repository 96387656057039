/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_LIST_COURSE,
  GET_LIST_HOTEL,
  GET_LIST_TRANSACTION,
  ADD_NEW_COURSE,
  GET_LIST_TEE,
  GET_LIST_HOLE,
  DELETE_COURSE,
  ACTIVE_COURSE,
  INACTIVE_COURSE,
  GET_COURSE_DETAIL,
  GET_TEE_DETAIL,
  UPDATE_COURSE_DETAIL,
  ADD_NEW_TEE,
  ACTIVE_TEE,
  INACTIVE_TEE,
  DELETE_TEE,
  UPDATE_TEE,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListCourse(data) {
  return fetch(ROOT + GET_LIST_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListHotel(data) {
  return fetch(ROOT + GET_LIST_HOTEL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListTransaction(data) {
  return fetch(ROOT + GET_LIST_TRANSACTION, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addCourse(data) {
  return fetch(ROOT + ADD_NEW_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListTee(data) {
  return fetch(ROOT + GET_LIST_TEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListHole(data) {
  return fetch(ROOT + GET_LIST_HOLE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteCourse(data) {
  return fetch(ROOT + DELETE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function activeCourse(data) {
  return fetch(ROOT + ACTIVE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function inActiveCourse(data) {
  return fetch(ROOT + INACTIVE_COURSE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getCourseDetail(data) {
  return fetch(ROOT + GET_COURSE_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateCourseDetail(data) {
  return fetch(ROOT + UPDATE_COURSE_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getTeeDetail(data) {
  return fetch(ROOT + GET_TEE_DETAIL, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewTee(data) {
  return fetch(ROOT + ADD_NEW_TEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function activeTee(data) {
  return fetch(ROOT + ACTIVE_TEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function inActiveTee(data) {
  return fetch(ROOT + INACTIVE_TEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteTee(data) {
  return fetch(ROOT + DELETE_TEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateTee(data) {
  return fetch(ROOT + UPDATE_TEE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const courseSetting = {
  getListCourse,
  getListHotel,
  getListTransaction,
  addCourse,
  getListTee,
  getListHole,
  deleteCourse,
  activeCourse,
  inActiveCourse,
  getCourseDetail,
  updateCourseDetail,
  getTeeDetail,
  addNewTee,
  activeTee,
  inActiveTee,
  deleteTee,
  updateTee,
}
