/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  API_RCAC01,
  API_UCAC01,
  GET_LIST_CADDY,
  API_RCAC02,
  API_RCA03,
  API_RCA05,
  API_ICAC01,
  API_DCAC01,
  API_RCAC03,
  API_UCAC02,
  API_UCAC04,
  API_RCWS01,
  API_UCAC05
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListCaddyCalendar(data) {
  return fetch(ROOT + API_RCAC01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UCAC05(data) {
  return fetch(ROOT + API_UCAC05, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UCAC04(data) {
  return fetch(ROOT + API_UCAC04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RCWS01(data) {
  return fetch(ROOT + API_RCWS01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RCAC02(data) {
  return fetch(ROOT + API_RCAC02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RCA03(data) {
  return fetch(ROOT + API_RCA03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RCA05(data) {
  return fetch(ROOT + API_RCA05, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_ICAC01(data) {
  return fetch(ROOT + API_ICAC01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getListCaddy(data) {
  return fetch(ROOT + GET_LIST_CADDY, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateCaddyCalendar(data) {
  return fetch(ROOT + API_UCAC01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DCAC01(data) {
  return fetch(ROOT + API_DCAC01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UCAC02(data) {
  return fetch(ROOT + API_UCAC02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RCAC03(data) {
  return fetch(ROOT + API_RCAC03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const caddyCalendar = {
  getListCaddyCalendar,
  updateCaddyCalendar,
  getListCaddy,
  api_RCAC02,
  api_RCA03,
  api_RCA05,
  api_ICAC01,
  api_DCAC01,
  api_UCAC02,
  api_RCAC03,
  api_UCAC04,
  api_RCWS01,
  api_UCAC05
}
