/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  API_RBKSA1,
  API_UBKSA1,
  API_UBKSA2,
  API_RBKSA2,
  API_UBK02B,
  API_UCI01,
  API_IBKSA1,
  API_RBKSA3,
  SGMS_URL,
  API_BG_UBK01S
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function api_RBKSA1(data) {
  return fetch(ROOT + API_RBKSA1, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_BG_UBK01S(data) {
  return fetch(SGMS_URL + API_BG_UBK01S, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UBKSA(data, isInto) {
  const api = isInto ? API_UBKSA1 : API_UBKSA2
  return fetch(ROOT + api, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RBKSA2(data) {
  return fetch(ROOT + API_RBKSA2, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UBK02B(data) {
  return fetch(ROOT + API_UBK02B, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UCI01(data) {
  return fetch(ROOT + API_UCI01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IBKSA1(data) {
  return fetch(ROOT + API_IBKSA1, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RBKSA3(data) {
  return fetch(ROOT + API_RBKSA3, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const starter = {
  api_RBKSA1,
  api_UBKSA,
  api_RBKSA2,
  api_UBK02B,
  api_UCI01,
  api_IBKSA1,
  api_RBKSA3,
  api_BG_UBK01S
}
