<template>
  <div>
    <template v-if="user.Avatar || user.Member && user.Member.Avatar">
      <b-avatar
        v-if="user.Avatar"
        :size="size"
        badge-variant=""
        :src="'data:image/jpeg;base64,' + user.Avatar"
      >
        <template #badge>
          <ez-icon
            icon="ezGolf-icon-flag-for-vietnam"
            :size="16"
          />
        </template>
      </b-avatar>
      <b-avatar
        v-else
        :size="size"
        badge-variant=""
        :src="'data:image/jpeg;base64,' + user.Member.Avatar"
      >
        <template #badge>
          <ez-icon
            icon="ezGolf-icon-flag-for-vietnam"
            :size="16"
          />
        </template>
      </b-avatar>
    </template>
    <template v-else>
      <b-avatar
        badge-variant=""
        :size="size"
      >
        <template #badge>
          <ez-icon
            icon="ezGolf-icon-flag-for-vietnam"
            :size="16"
          />
        </template>
      </b-avatar>
    </template>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    size: {
      type: Number || String,
      default: 54,
    },
    user: {
      type: Object,
      default() {
        return {
          Avatar: '',
        }
      },
    },
  },
}
</script>
