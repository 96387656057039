<template>
    <b-modal
        id="modal-select-time"
        v-model="modalShow"
        centered
        no-close-on-backdrop
        hide-header
        size="sm"
      >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0 text-primary">
            {{ $t('golf_booking_select_time') }}
          </h4>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="hideModal"
          />
        </div>
      </b-card-header>
        <div class="p-1">
            <cleave
                v-model="dateInput"
                class="form-control"
                :raw="false"
                :options="inputMaskTime"
                placeholder="hh:mm"
            />
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="secondary"
              size="md"
              class="float-right"
              @click="hideModal"
            >
            {{ $t('golf_common_back') }}
            </b-button>
            <b-button
              variant="primary"
              size="md"
              class="float-right mr-1"
              @click="save"
            >
            {{ $t('golf_common_confirm') }}
            </b-button>
          </div>
        </template>
      </b-modal>
</template>
<script>
import Cleave from 'vue-cleave-component'

export default {
    components: {
        Cleave
    },
    props: ['PropsDataTeeOffFinish'],
    data() {
        return {
            modalShow: false,
            inputMaskTime: {
                time: true,
                timePattern: ['h', 'm'],
            },
            dateInput: `${new Date().getHours()}:${new Date().getMinutes() - 1}`
        }
    },
    watch: {
      modalShow(value) {
        if(value) {
          this.dateInput =  `${new Date().getHours()}:${new Date().getMinutes() - 1}`
        }
      }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-select-time')
        },
        save() {
            const TimeTemp = this.dayjs().utc(localStorage.getItem('timeZone'))
            .set('hour', this.dateInput.split(':')[0])
            .set('minute', this.dateInput.split(':')[1])
            .set('second', 0)
            .add(-(localStorage.getItem('timeZoneOffset') / 60), 'hour')
            .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
            if(this.PropsDataTeeOffFinish == 'TeeOff') {
              this.$emit('event', {type: 'after-select-time-teeoff', time: TimeTemp})
            } else {
              this.$emit('event', {type: 'after-select-time-finish', time: TimeTemp})
            }
            this.hideModal()
        }
    },
}
</script>
<style lang="scss">
    
</style>