<template>
  <b-modal
    id="modal-primary"
    v-model="dialog"
    ok-variant="primary"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    :cancel-title="$t('golf_common_back')"
    :ok-title="$t('golf_common_confirm')"
    modal-class="modal-primary"
    centered
    size="xs"
    hide-header
    :hide-footer="item.dataConfirm.hideFooter"
    auto-focus-button="ok"
    @ok="confirmDialog"
    @cancel="cancelDialog"
  >
    <b-card-header class="banned-background-color-primary">
      <div class="d-flex justify-content-between align-items-center block-header">
        <h5 class="mb-0 title-modal color-primary">
          {{ item.dataConfirm.title }}
        </h5>
        <feather-icon
          icon="XIcon"
          size="24"
          class="cursor-pointer color-primary"
          @click="close"
        />
      </div>
    </b-card-header>
    <b-card-text>
      <div style="padding: 20px 16px">
        {{ item.dataConfirm.content }}
      </div>
    </b-card-text>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      item: {
        dataConfirm: {
          title: '',
          content: '',
          hideFooter: false,
        },
      },
    }
  },
  watch: {
    // dialog(value) {
    //   if(!value) {
    //     this.$emit('cancelDialog', this.item)
    //   }
    // }
  },
  methods: {
    open(item) {
      this.dialog = true
      this.item = item
    },
    close() {
      this.cancelDialog()
      this.dialog = false
    },
    confirmDialog() {
      this.$emit('confirmDialog', this.item)
      this.dialog = false
    },
    cancelDialog() {
      this.$emit('cancelDialog', this.item)
    }
  },
}
</script>
<style lang="scss">
#modal-primary {
  .block-header {
    .title-modal {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .color-primary {
    color: #114A9F;
  }

  .w-50 {
    width: 65px !important;
  }

  .color-warning {
    color: #FF9F43;
  }
}
</style>