import dayjs from 'dayjs'
import { starter } from '@/api/starter'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import ShortKey from '@/views/modules/ultils/ShortKey'
import { CoolSelect } from 'vue-cool-select'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Cleave from 'vue-cleave-component'
import History from '@/views/modules/booking/modal/History'

import { booking } from '@/api/booking'
import { commonServices } from '@/api/common-services'
import { update_caddy } from '@/views/modules/booking/params/update_caddy'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import UpdateCaddy from '@/views/modules/starter/UpdateCaddy'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S
} from '@/api/constant.js'

export default {
  name: 'slideBarStarter',
  props: [
    'dataBooking', 'isManualCheckDupCaddie','searchDefault'
  ],
  components: {
    'confirm-dialog': ConfirmDialog,
    CoolSelect,
    UserAvatar,
    Treeselect,
    'delete-dialog': DeleteDialog,
    UpdateCaddy,
    ShortKey,
    Cleave,
    History
  },
  data() {
    return {
      selected: null,
      items: [],
      loading: false,
      timeoutId: null,
      noData: false,
      bookingId: null,
      BookingDetail: null,
      caddy: [],
      listTransaction: [],
      listService: [],
      hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
      zone: [],
      allServices: [],

      productName: 'Chọn dịch vụ',
      activePosition: {
        ProductMenuId: null,
        ProductId: null,
      },
      selectedServices: [],
      TotalAmount: 0,
      isChange: false,
      modalShow: false,
      detailHole: null,
      lineTee: [],
      line: [],
      optionHole: [],
      listCaddy: [],
      BDC: null,
      keyWord: null,
      isOpen: false,
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S],
      allChildComponentClosed: [],
      IsAutoChangeCaddy: null,
      ServiceIdChargeCaddy: null,
      BagtagSearch: null,
      visibleInfoGolf: true,
      listWorkingCaddyStatus: null,
      disableButton: false,
      CourseIdEdit: null,
      isShowSelectType: false,
      listAdditionalType: null,
      AdditionalTypeSelect: null,
      showActualNumberOfHole: null,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      dataHistory: null,
    }
  },
  created() {
    for (let index = 1; index <= 10; index++) {
      this.optionHole.push({
        id: index * 9,
        label: `${index * 9} hole`,
      })
    }
    this.getListZone()
  },
  watch: {
    selected(val) {
      this.dataBooking = val
    },
    dataBooking(val) {
      this.bookingId = val.Id
      this.call_api_RBKSA2()
      this.isChange = false
    },
    dataService: {
      handler(val) {
        this.TotalAmount = 0

        for (const key in val) {
          if (Object.prototype.hasOwnProperty.call(val, key)) {
            const element = val[key]
            element.forEach(sv => {
              this.TotalAmount += (sv.UnitPrice * sv.Quantity)
            })
          }
        }
      },
      deep: true,
    },
    // 'detailHole.TotalNumberOfHole': {
    //   handler(val) {
    //     if (val) {
    //       this.line = []
    //       for (let index = 0; index < (val / 9); index++) {
    //         this.line.push(
    //           {
    //             Id: -1,
    //             TeeName: '',
    //           },
    //         )
    //       }
    //     }
    //   },
    //   deep: true,
    // },
    isOpen(val) {
      this.$emit('is-open', { ComponentId: 'sidebar-detail-starter', IsActive: val })
    },
  },
  computed: {
    dataService() {
      const groupBy = function (xs, key) {
        return xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }
      return this.BookingDetail ? groupBy(this.BookingDetail.BookingDetailService, 'CreateBy') : {}
    },
    dataServiceByReceptionist() {
      const groupBy = function (xs, key) {
        return xs.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x)
          return rv
        }, {})
      }
      return this.BookingDetail ? groupBy(this.BookingDetail.BookingDetailServiceByReceptionist, 'CreateBy') : {}
    },
    linePlay() {
      if (this.detailHole) {
        const lineName = this.detailHole.Line.length > 0 ? this.detailHole.Line.map(x => {
          if (x === -1) {
            return ''
          }
          return this.detailHole.Tee.find(t => t.Id === x).TeeName
        }) : []
        return lineName.join(' - ')
      }
    },
  },
  methods: {
    _dayjs: dayjs,
    onSearch(search) {
      const lettersLimit = 2
      this.noData = false
      if (search.length < lettersLimit) {
        this.items = []
        this.loading = false
        return
      }
      this.loading = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const data = {
          KeyWord: search,
          BookingCode: null,
          BookingDetail: null,
          BookingId: null,
        }

        booking.getDetailBooking2A(data)
          .then(
            response => {
              if (response.Status === '200') {
                this.items = []
                const TeeTime = response?.Data?.TeeTime || []
                if (TeeTime.length > 0) {
                  TeeTime.forEach(tee => {
                    if (tee.Booking && tee.Booking.length > 0) {
                      tee.Booking.forEach(bk => {
                        if (bk.BookingDetail && bk.BookingDetail.length > 0) {
                          bk.BookingDetail.forEach(bkd => {
                            this.items.push(bkd)
                          })
                        }
                      })
                    }
                  })
                }
                this.loading = false
                if (!this.items.length) this.noData = true
              } else {
                this.loading = false
                this.noData = true
              }
            },
            error => {
              this.noData = true
            },
          )
      }, 500)
    },
    handleEvent(event) {
      if (event.type == 'after_add_new_caddy' || event.type == 'after_update_caddy') {
        this.call_api_RBKSA2()
        this.$emit('refresh')
      }
    },
    SearchBookingDetail(type) {
      if(type == 'BDC') {
        if(this.BDC ? this.BDC.length > 3 ? true : false  : false) {
          this.call_api_RBKSA2({
            BDC: this.BDC,
            Bagtag: null,
            BookingDetail: {
              Id: this.dataBooking.Id,
            },
          })
        }
      } else if (type == 'Bagtag') {
          this.call_api_RBKSA2({
            BDC: null,
            Bagtag: this.BagtagSearch,
            BookingDetail: {
              Id: this.dataBooking.Id,
            },
          })
      }
    },
    async call_api_RBKSA2(data) {
      this.loading = true
      if(data) {
        var body = data
      } else {
        var body = {
          BDC: this.BDC,
          Bagtag: this.BagtagSearch,
          BookingDetail: {
            Id: this.dataBooking.Id,
          },
        }
      }
      

      await starter
        .api_RBKSA2(body)
        .then(res => {
          if (res.Status == '200') {
            res.Data.BookingDetail.BookingDetailCaddy.forEach(x => {
              let total = 0
              if(x.BookingDetailService.length > 0) {
                x.BookingDetailService.forEach(y => {
                  total += y.TotalAmount
                })
              } 
              x.BookingDetailServiceTotalAmount = total
            })
            this.BookingDetail = res.Data.BookingDetail
            this.TotalAmount = 0
            this.total()
            this.caddy = []
            this.caddy.push({
              id: this.BookingDetail.CaddyId,
              label: this.BookingDetail.CaddyName,
            })
            this.getListCaddy_RCA04()
          } else {
            this.showResToast(res)
          }
        })
      this.loading = false
      setTimeout(() => { 
        if(this.searchDefault == 'BDC') {
          this.$refs.BDC.$el.focus()
        } else if(this.searchDefault == 'BAGTAG'){
          this.$refs.Bagtag.$el.focus()
        }
      }, 10)
    },
    hideSideBar() {
      this.BDC = null
      if (this.isChange === true) {
        this.$refs.confirmChangeOutPage.open({
          Id: -1,
        })
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-starter')
        this.$emit('refresh')
        this.detailHole = null
      }
    },
    hideModalUpdateHole() {
      this.modalShow = false
      this.call_api_RBKSA2()
    },
    confirmOutPage() {
      this.updateBookingService()
      // this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-starter')
      this.detailHole = null
    },
    async updateBookingService() {
      this.disableButton = true
      this.isChange = false
      const sv = []
      this.BookingDetail.BookingDetailService.forEach(item => {
        sv.push({
          ServiceId: item.ServiceId,
          UnitPrice: +item.UnitPrice,
          Quantity: +item.Quantity,
          Amount: +item.TotalAmount,
          TotalAmount: +(item.UnitPrice * item.Quantity),
          BookingDetailServicesDiscount: [],
          Id: (item.Id) ? item.Id : 0,
          ZoneId: Number(item.ZoneId)
        })
      })
      const data = {
        RetailBy: 'STARTER',
        BookingDetail: {
          Id: this.BookingDetail.Id,
          BuggyCode: this.BookingDetail.BuggyCode,
          BookingDetailServices: [...sv],
        },
      }
      const body = {
        BookingDetail: {
          Id: this.BookingDetail.Id,
          NoteOfStarter: this.BookingDetail.NoteOfStarter,
          BuggyCode: this.BookingDetail.BuggyCode,
        },
        RetailBy: 'STARTER',
      }
      await booking.api_UBK10(body)
      await booking.updateBookingService(data).then(res => {
        this.showResToast(res)
        this.$emit('refresh')
        this.call_api_RBKSA2(this.dataBooking)
        this.disableButton = false
      })
    },
    async getListCaddy_RCA04() {
      const data_request = {
        BookingDetailId: this.dataBooking.Id,
        CaddyWorkingStatus: this.listWorkingCaddyStatus.split(',')
      }

      const response = await booking.getListCaddy(data_request)
      if (response.Status === '200') {
        this.caddy = response.Data.Caddy.map(x => ({
          id: x.Id,
          label: x.CaddyName,
        }))
        this.listCaddy = response.Data.Caddy
        this.getDetailHole()
      }
    },
    getDetailHole() {
      const data_request = {
        BookingDetailId: this.BookingDetail.Id,
      }

      booking.api_RBK09(data_request).then(res => {
        if (res.Status === '200') {
          this.detailHole = res.Data
          var checkEdit = false
          this.AdditionalTypeSelect = res.Data.RateAdditionalType4ActualHole
          this.detailHole.BookingDetailCourse.forEach(course => {
            // const arr = this.detailHole.TeeLink.filter(e => e.CourseId === course.CourseId).map(x => x.TeeStartId)
            // course.TeeId = [...Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse)]
            const arr = this.detailHole.Tee.filter(e => e.CourseId === course.CourseId).map(x => x.Id)
            course.TeeId = [...Array.from(new Set(arr.map(JSON.stringify))).map(JSON.parse)]


            const listHole = []
            for(let i = 0; i < course.NumberOfHole; i++) {
              listHole.push(i + 1)
            }
            course.ListHole = listHole
            course.ActualNumberOfHole = course.ActualNumberOfHole ? course.ActualNumberOfHole : course.NumberOfHole
            if(course.ActualNumberOfHole != course.NumberOfHole) {
              checkEdit = true
              this.CourseIdEdit = course.CourseId
              this.isShowSelectType = true
            } 
          })
          if(!checkEdit) {
            this.CourseIdEdit = null
            this.isShowSelectType = false
          }
        
          if (this.detailHole.Line.length === 0) {
            for (let index = 0; index < (this.detailHole.TotalNumberOfHole / 9); index++) {
              this.line.push({
                Id: -1,
                TeeName: '',
              })
            }
          }
          this.detailHole.Tee.forEach(tee => {
            tee.TeeEndIds = []
            this.detailHole.TeeLink.forEach(teelink => {
              if (teelink.TeeStartId === tee.Id) {
                tee.TeeEndIds.push(teelink.TeeEndId)
              }
            })
          })
        }
      })
    },
    chooseTee(tee, col) {
      this.detailHole.Line.splice(col, 1, tee.Id)
      this.Line = []
      this.detailHole.Line.forEach(x => {
        this.Line.push(this.detailHole.Tee.find(y => y.Id == x))
      })
      this.detailHole.BookingDetailCourse.forEach(course => {
        course.NumberOfHole = 0
      })
      this.detailHole.Line.forEach((tee, index) => {
        if (index < this.detailHole.Line.length) {
          let isExistCourse = false
          this.detailHole.BookingDetailCourse.forEach(course => {
            if (course.TeeId.includes(tee)) {
              course.NumberOfHole += 9
              isExistCourse = true
            }
          })
          if (!isExistCourse) {
            // dung.trinh khi Tee khong nam trong Booking detail course thi add them.
            const tee0 = this.detailHole.Tee.find(obj => obj.Id == tee)
            const bkdCourse = {
              // Id: null,
              CourseId: tee0.CourseId,
              CourseName: tee0.CourseName,
              NumberOfHole: 9, // Mac dinh ban dau la 9 ho
              TeeId: [tee],
            }
            this.detailHole.BookingDetailCourse.push(bkdCourse)
          }
        }
      })
      this.detailHole.BookingDetailCourse.forEach(x => {
        const listHole = []
        for(let i = 0; i < x.NumberOfHole; i++) {
          listHole.push(i + 1)
        }
        x.ListHole = listHole
        x.ActualNumberOfHole = x.NumberOfHole
      })
      this.CourseIdEdit = null
      this.isShowSelectType = false

    },
    call_api_UBK02B() {
      if (this.line.filter(x => x.Id === -1).length > 0) {
        this.showToast('error', 'Vui lòng chọn đường chơi bóng')
        return
      }
      if(this.showActualNumberOfHole == 1 && this.CourseIdEdit && !this.AdditionalTypeSelect) {
        this.showToast('error', this.$t('golf_booking_please_select_additional_type'))
        return
      } 
      const BookingDetailCourse = this.detailHole.BookingDetailCourse.filter(x => x.NumberOfHole > 0).map(x => ({
        Id: x.Id,
        CourseId: x.CourseId,
        NumberOfHole: +x.NumberOfHole,
        ActualNumberOfHole: +x.ActualNumberOfHole
      }))
      let BookingDetailCourseTemp = []
      BookingDetailCourse.forEach(x => {
        let data = BookingDetailCourseTemp.find(y => y.CourseId == x.CourseId)
        if(data) {
          data.NumberOfHole += x.NumberOfHole
          data.ActualNumberOfHole += x.ActualNumberOfHole
        } else {
          BookingDetailCourseTemp.push(x)
        }
      })
      const body = {
        BookingDetail: {
          Id: this.BookingDetail.Id,
          Line: this.detailHole.Line,
          BookingDetailCourse: BookingDetailCourseTemp
        },
		    RateAdditionalType: this.AdditionalTypeSelect,
        IsLock: false
      }
      if(this.dataBooking.type == 'UpdateHoleAll') {
        this.$emit('event', {type: 'after-change_all_hole', data: body})
        this.modalShow = false
      } else {
        starter
        .api_UBK02B(body)
        .then(res => {
          if (res) {
            if(res.Status === '200') {
              if(this.BookingDetail.BookingDetailCaddy.length === 1) {
                const dataConfirm = {
                    dataConfirm: {
                      title: 'Cập nhật Caddy',
                      content: 'Bạn có muốn cập nhật số hố caddy?',
                      data: this.BookingDetail.BookingDetailCaddy[0]
                    },
                  }
                if(this.IsAutoChangeCaddy == 0) {
                  this.$refs.confirmUpdateCaddy.open(dataConfirm)
                } 
              } else if (this.BookingDetail.BookingDetailCaddy.length > 1) {
                this.showToast('error', `Có ${this.data.BookingDetail.BookingDetailCaddy.length} caddy phục vụ. Vui lòng cập nhật số hố caddy`)
              } 
            }
            this.showResToast(res)
            this.call_api_RBKSA2()
            this.modalShow = false
            this.$emit('refresh')
          }
        })
      }
      
    },
    updateCaddy(item) {
      let payload
      {
        payload = {
          BookingDetailCaddy: {
            Id: item.dataConfirm.data.Id,
            BookingDetailId: this.BookingDetail.Id,
            CaddyId: Number(item.dataConfirm.data.CaddyId),
            NumberOfHole: this.detailHole.TotalNumberOfHole,
            WorkingStatus: item.dataConfirm.data.WorkingStatus,
            Reasons: item.dataConfirm.data.Reasons,
            IsDeleted: item.dataConfirm.data.IsDeleted,
            BookingDetailServices: [],
          },
        }
      }

      booking.updateCaddy(payload).then(response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.call_api_RBKSA2()
          this.$emit('refresh')
        }
      })
    },
    setCaddy(val) {
      if (val) {
        const payload = update_caddy
        payload.BookingDetail.Id = +this.bookingId
        payload.BookingDetail.CaddyId = +val
        booking.updateCaddy(payload).then(res => {
          if (res.Status === '200') {
            this.showToast('success', this.$t('update_caddy_success'))
          } else {
            this.showToast('error', this.$t('update_caddy_error'))
          }
        })
      }
    },

    async getListZone() {
      const bodyArea = {
        KeyGroup: 'BOOKING_CONFIG',
      }
      await commonServices
        .getCommon(bodyArea)
        .then(res => {
          if (res.Status === '200') {
            res.Data.forEach(x => {
              if (x.KeyCode === 'BAR_LOCATION_4_STATER') {
                this.zone.push(x.KeyValue)
              }
            })
            this.ServiceIdChargeCaddy = res?.Data.find(x => x.KeyCode === 'BOOKING_CADDY_CHARGE_FEE').KeyValue
            this.IsAutoChangeCaddy = res?.Data.find(x => x.KeyCode === 'BOOKING_AUTO_UPDATE_HOLE_OF_CADDIE').KeyValue
            this.listWorkingCaddyStatus = res?.Data.find(x => x.KeyCode === 'BOOKING_CADDY_STATUS_ALLOW_BOOKING').KeyValue
            this.getListService()
            this.showActualNumberOfHole = res?.Data.find(x => x.KeyCode === 'BOOKING_SHOW_ACTUAL_NUMBER_OF_HOLE').KeyValue
            this.listAdditionalType = []
            const listAdditionalTypetemp = res?.Data.find(x => x.KeyCode === 'ADDITIONALFEE_ODD_HOLE_TYPE').KeyValue.split(',')
            listAdditionalTypetemp.forEach(x => {
              this.listAdditionalType.push({
                Code: x, 
                Name: this.$t(x.toLocaleLowerCase())
              })
            })
          }
        })
    },

    async getListService() {
      const body = {
        HotelId: this.hotelId,
        ZoneId: this.zone,
      }
      await commonServices
        .getListService(body)
        .then(res => {
          if (res.Status === '200') {
            this.listService = res.Data.Menu
            this.allServices = res.Data.Menu
            if (res.Data.Menu) {
              this.allServices.forEach(x => {
                x.MenuDetail.forEach(y => {
                  y.ProductNameUpperCase = y.ProductName.split(' ').join('').toUpperCase()
                })
              })
            }
            // Hiển thị tên service đã được chọn
            // this.getServiceNameById(this.value)
          }
        })
    },
    async getServiceNameById(serviceId) {
      await this.listService.find(x => {
        x.MenuDetail.find(y => {
          if (y.ProductId === serviceId) {
            this.productName = y.ProductName
            this.activePosition.ProductMenuId = x.ProductMenuId
            this.activePosition.ProductId = y.ProductId
          }
        })
      })
    },
    selectSevice(item, zoneId) {
      if (this.BookingDetail.BookingDetailService.length === 0) {
        this.BookingDetail.BookingDetailService.push({
          CreateBy: 'Tạo mới',
          CreateDate: this.utcConvertToLocalTimeZone(null, 'DD/MM/YYYY HH:mm'),
          Amount: item.TotalAmount,
          Discount: [],
          Quantity: 1,
          ServiceId: item.ProductId,
          ServiceName: item.ProductName,
          TotalAmount: item.ProductPrice,
          UnitPrice: item.ProductPrice,
          ZoneId: zoneId,
          IsOpenProduct: item.IsOpenProduct,
        })
      } else {
        if (this.BookingDetail.BookingDetailService.filter(e => e.ServiceId === item.ProductId).length > 0) {
          this.showToast('error', 'Đã tồn tại dịch vụ')
          return
        }
        this.BookingDetail.BookingDetailService.push({
          CreateBy: 'Tạo mới',
          Amount: item.TotalAmount,
          Discount: [],
          Quantity: 1,
          ServiceId: item.ProductId,
          ServiceName: item.ProductName,
          TotalAmount: item.ProductPrice,
          UnitPrice: item.ProductPrice,
          ZoneId: zoneId,
          IsOpenProduct: item.IsOpenProduct,
        })
      }
      this.total()
      this.isChange = true
    },
    total() {
      this.TotalAmount = 0
      this.BookingDetail.BookingDetailService.forEach(sv => {
        this.TotalAmount += (sv.UnitPrice * sv.Quantity)
      })
    },
    handleSearch() {
      // const search = new RegExp(this.keyWord, 'i')
      const tmpAllService = []
      this.allServices.forEach(x => {
        const menuDetail = []
        x.MenuDetail.forEach(y => {
          if (y.ProductNameUpperCase.includes(this.keyWord.split(' ').join('').toUpperCase())) {
            menuDetail.push(y)
          }
        })

        if (menuDetail.length) {
          tmpAllService.push({
            ProductMenuId: x.ProductMenuId,
            MenuCode: x.MenuCode,
            MenuName: x.MenuName,
            IsChildOfBar: x.IsChildOfBar,
            ParentMenuId: x.ParentMenuId,
            IsActive: x.IsActive,
            HotelId: x.HotelId,
            MenuDetail: menuDetail,
          })
        }
      })

      this.listService = tmpAllService
    },
    removeItem(service) {
      if(this.ServiceIdChargeCaddy == service.ServiceId) {
        this.showToast('error', this.$t('delete_caddy_chagre_error'))
        return
      }
      const index = this.BookingDetail.BookingDetailService.map(e => e.ServiceId).indexOf(service.ServiceId)

      this.BookingDetail.BookingDetailService.splice(index, 1)
      this.total()
      this.isChange = true
    },
    popOrPushCourse() {
      this.dataBooking.Id = this.BookingDetail.Id
      this.dataBooking.EntryTime = this.BookingDetail.EntryTime
      this.dataBooking.ExitTime = this.BookingDetail.ExitTime
      this.dataBooking.IsDetail = true
      this.$emit('popOrPush', this.dataBooking)
    },

    async openCaddyModal() {
      this.openModal('RCAG01', 'modal-update-caddy')
    },
    changeHole() {
      this.detailHole.Line = this.detailHole.DefaultLine.filter(x => x.TeeStart == this.detailHole.PlayerPosition.TeeId && x.NumberOfHoles == this.detailHole.TotalNumberOfHole && x.CourseId == this.detailHole.Tee.find(y => y.Id == this.detailHole.PlayerPosition.TeeId).CourseId)[0].Tee
      this.Line = []
      this.detailHole.Line.forEach(x => {
        this.Line.push(this.detailHole.Tee.find(y => y.Id == x))
      })
      this.detailHole.BookingDetailCourse.forEach(course => {
        course.NumberOfHole = 0
      })
      this.detailHole.Line.forEach((tee, index) => {
        if (index < this.detailHole.Line.length) {
          let isExistCourse = false
          this.detailHole.BookingDetailCourse.forEach(course => {
            if (course.TeeId.includes(tee)) {
              course.NumberOfHole += 9
              isExistCourse = true
            }
          })
          if (!isExistCourse) {
            // dung.trinh khi Tee khong nam trong Booking detail course thi add them.
            const tee0 = this.detailHole.Tee.find(obj => obj.Id == tee)
            const bkdCourse = {
              // Id: null,
              CourseId: tee0.CourseId,
              CourseName: tee0.CourseName,
              NumberOfHole: 9, // Mac dinh ban dau la 9 ho
              TeeId: [tee],
            }
            this.detailHole.BookingDetailCourse.push(bkdCourse)
          }
        }
      })
      this.detailHole.BookingDetailCourse.forEach(x => {
        const listHole = []
        for(let i = 0; i < x.NumberOfHole; i++) {
          listHole.push(i + 1)
        }
        x.ListHole = listHole
        x.ActualNumberOfHole = x.NumberOfHole
      })
      this.CourseIdEdit = null
      this.isShowSelectType = false

    },
    openModal(key, id) {
      this.checkPermissionOpenModal(key, id)
    },
    onTriggeredEventHandler(payload) {
      if(payload.evt.ctrlKey && payload.key === S) {
        if(!this.disableButton) {
          this.updateBookingService()
        }
      }
      if (payload.evt.ctrlKey && payload.key === F) {
        if(this.searchDefault == 'BDC') {
          this.$refs.BDC.$el.focus()
        } else if(this.searchDefault == 'BAGTAG'){
          this.$refs.Bagtag.$el.focus()
        }
      }
      if (payload.key === F2) {
        if (!this.BookingDetail.ExitTime) {
          if (!this.BookingDetail.EntryTime) {
            this.popOrPushCourse()
          } else {
            this.showToast('error', 'Golfer đã vào sân')
          }
        } else {
          this.showToast('error', 'Golfer đã ra sân')
        }
      }
      if (payload.key === F3) {
        if (!this.BookingDetail.ExitTime) {
          if (this.BookingDetail.EntryTime) {
            this.popOrPushCourse()
          } else {
            this.showToast('error', 'Golfer chưa vào sân')
          }
        } else {
          this.showToast('error', 'Golfer đã ra sân')
        }
      }
      if (payload.key === F4) {
        this.hideSideBar()
      }
    },
    EditActualNumberOfHole(data) {
      if(data.ActualNumberOfHole != data.NumberOfHole) {
        this.CourseIdEdit = data.CourseId
        this.isShowSelectType = true
      } else {
        this.CourseIdEdit = null
        this.isShowSelectType = false
      }
    },
    viewHistory() {
        this.dataHistory = {
          BookingDetail: this.BookingDetail,
          Id: this.BookingDetail.BookingId
        }
      this.$refs.History.open(this.dataHistory)
    }
  },
}
