export const update_caddy = {
  BookingDetail: {
      Id:0,
      CaddyId:0,
      BookingDetailServices:[
        {
          ServiceId:0,
          UnitPrice:0,
          Quantity:0,
          Amount:0,
          TotalAmount:0,
          TransactionCode:""
        }
      ]
    }
}
