<template>
  <div>
    <b-modal
      id="modal-history"
      v-model="isOpen"
      size="md"
      centered
      hide-header
      ok-only
      hide-footer
    >
      <b-card-header class="banned-background-color-primary">
        <div class="d-flex justify-content-between align-items-center block-header">
          <div class="d-flex justify-content-start align-items-center">
            <p class="mb-0 title-modal color-primary mr-1">
              {{ $t('golf_booking_history_action') }}
            </p>
          </div>
          <feather-icon
            icon="XIcon"
            size="24"
            class="cursor-pointer color-primary"
            @click="isOpen = false"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <!-- <b-row class="mb-3">
          <b-col md="3">
            <b-form-group>
              <b-form-input
                id="searchNameInput"
                v-model="TransactionType"
                :placeholder="$t('golf_log_type')"
                :autofocus="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="pl-0 pr-0"
          >
            <b-form-group>
              <date-range-picker
                ref="picker"
                v-model="OpenDate"
                :single-date-picker="true"
                opens="right"
                :ranges="false"
                :time-picker="false"
                :time-picker-increment="1"
                :show-dropdowns="true"
                :auto-apply="true"
                :locale-data="{
                  format: 'DD/MM/YYYY',
                  daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                  firstDay: 0
                }"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="pr-0"
          >
            <b-form-group>
              <v-select
                v-model="UserId"
                :options="Cashiers"
                label="label"
                :reduce="item => item.id"
                :placeholder="$t('golf_user_id')"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="call_api_RHS01"
            >
              Tìm kiếm
            </b-button>
          </b-col>
        </b-row> -->
        <b-overlay :show="isLoading" :opacity="0.3" rounded="sm">
          <app-timeline
            v-if="dataTrans"
            class="time-line-history"
          >

            <app-timeline-item
              v-for="(item, index) in dataTrans.Transaction"
              :key="index"
              variant="warning"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ item.Bdc }} - {{ item.TransactionCodeName }}</h6>
                <small class="text-muted">{{ utcConvertToLocalTimeZone(item.TransactionDate, 'DD/MM/YYYY HH:mm:ss') }}</small>
              </div>
              <p>{{ $t('golf_booking_history_old_value') }}: {{ item.OldValue }}</p>
              <p>{{ $t('golf_booking_history_new_value') }}: {{ item.NewValue }}</p>
              <p><span>{{ $t('golf_booking_history_create_by_name') }}: <span v-if="item.CreateByName">{{ item.CreateByName.CashierName }}</span></span></p>
            </app-timeline-item>
          </app-timeline>
        </b-overlay>
        
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import { booking } from '@/api/booking'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'UpdateHole',
  components: {
    Treeselect,
    AppTimeline,
    AppTimelineItem,
  },
  props: [
    'data',
  ],
  data() {
    return {
      isOpen: false,
      dataTrans: null,
      OpenDate: this.getUTC('start', 'day'),
      TransactionType: null,
      UserId: null,
      Cashiers: [],
      isLoading: false
    }
  },
  computed: {},
  watch: {
    // data(value) {
    //   if(value.BookingDetail.Id) {
    //     this.call_api_RHS01()
    //   }
    // },
  },
  mounted() {
    // this.getListCashier_RCS01()
  },
  methods: {
    async open(data) {
      this.dataTrans = null
      this.isOpen = true
      await this.call_api_RHS01(data)
    },
    async call_api_RHS01(data) {
      this.isLoading = true
      const data_request = {
        BookingId: data.Id,
        BookingDetailId: data.BookingDetail.Id,
        TransactionType: this.TransactionType,
        Date: this.OpenDate,
        UserId1: this.UserId,
      }

      await booking.api_RHS01(data_request).then(res => {
        if (res.Status === '200') {
          this.dataTrans = res.Data
        }
        this.isLoading = false
      })
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(async response => {
        if (response.Status === '200') {
          this.Cashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/variables';
@import '@core/scss/vue/libs/vue-slider.scss';
#modal-history {
  .modal-dialog {
    max-width: 650px !important;
  }
.banned-background-color-primary {
  background: rgba(17, 74, 159, 0.12);
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.color-primary {
  color: $primary;
}

.intro-wrapper {
  margin-bottom: 18px;

  .text-intro {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #424244;
  }
}

.box {
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(17, 74, 159, 0.38);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(17, 74, 159, 0.2);
    padding: 10px 14px;
    border-radius: 6px 6px 0 0;

    .user-name-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dot {
        width: 15px;
        height: 15px;
        background-color: $primary;
        border-radius: 100%;
        margin-right: 5px;
      }

      .user-name {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }

    .info-tee {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0;
    }
  }

  .body {
    padding: 14px;

    .spacing-4 {
      margin: 4px 0;

      .block {
        margin: 5px 0;
      }

      .play-line-block {
        width: 34px;
        height: 28px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #D6D9E1;
        box-sizing: border-box;
        cursor: pointer;

        p {
          color: #114A9F;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .btn-tee {
    padding: 6px;
  }

  .d-select-hole {
    width: 92px;
  }
}
.time-line-history{
  height: 400px;
    overflow-y: auto;
    padding: 0 10px;
}
}

</style>
